var site = window.site || {};

(function ($) {
  'use strict';

  Drupal.behaviors.siteSearchV1 = {
    isMobile: false,
    translations: {
      trendingProductsText: site.translations?.elc_search?.trending_results_title || null
    },
    event: '',
    selector: {
      html: 'html',
      body: 'body',
      siteHeader: '.js-site-header',
      headerSearch: '.js-header-search',
      headerSearchResults: '.js-header-search-results',
      viewportMeta: 'meta[name="viewport"]',
      searchIcon: '.js-header-search__icon',
      searchClose: '.js-header-search__close',
      searchForm: '.js-header-search-form',
      searchInput: '.js-search-field',
      searchOverlayLanding: '.search-overlay-landing',
      searchTrendingTitle: '.js-header-search-trending-title',
      searchGrid: '.js-sd-product-grid',
      searchOverlayBg: '.js-header-search-overlay-bg',
      searchPopularList: '.js-esearch-nav__popular-list',
      headerResults: '.js-header-search-results', 
      dimOverlay: '<div class="site-header__dim-overlay"></div>'
    },
    attach: function (context) {
      var self = this;
      var $containers = $(self.selector.headerSearch, context);
      var $searchPopularList = $(self.selector.searchPopularList, context);

      // If Unison is not ready on attach, it triggers change on initialization
      if ((Unison.fetch.now())) {
        self.isMobile = self.checkIfMobile();
      }
      Unison.on('change', function () {
        self.isMobile = self.checkIfMobile();
        self.event = self.isMobile ? 'click' : 'mouseenter';
        self.attach(context);
      });

      $containers.each(function () {
        var $this = $(this);

        self.getDom($this, context);
        self.setEvents($this);
      });

      $(self.selector.searchOverlayLanding, context).addClass('hidden');

      if ($searchPopularList.length > 0 && self.translations.trendingProductsText && $(self.selector.searchTrendingTitle, context).length == 0) {
        $('<div class="header-search-trending-title js-header-search-trending-title">' + self.translations.trendingProductsText + '</div>').insertAfter($searchPopularList);
      }
    },

    getDom: function ($that, context) {
      var self = this;

      $that.nodes = {};
      $that.nodes.$html = $(self.selector.html, context);
      $that.nodes.$body = $(self.selector.body, context);
      $that.nodes.$siteHeader = $(self.selector.siteHeader, context);
      $that.nodes.$headerSearchResults = $(self.selector.headerSearchResults, context);
      $that.nodes.$viewportMeta = $(self.selector.viewportMeta, context);
      $that.nodes.$searchIcon = $(self.selector.searchIcon, $that);
      $that.nodes.$searchClose = $(self.selector.searchClose, $that);
      $that.nodes.$searchForm = $(self.selector.searchForm, $that);
      $that.nodes.$searchInput = $(self.selector.searchInput, $that);
      $that.nodes.$searchOverlayLanding = $(self.selector.searchOverlayLanding, context);
      $that.nodes.$searchTrendingTitle = $(self.selector.searchTrendingTitle, $that.nodes.$searchOverlayLanding);
      $that.nodes.$searchGrid = $(self.selector.searchGrid, $that.nodes.$searchOverlayLanding);
      $that.nodes.$searchOverlayBg = $(self.selector.searchOverlayBg, context);
      $that.nodes.$headerResults = $(self.selector.headerResults, context);
      $that.nodes.$dimOverlay = $(self.selector.dimOverlay, context);
    },

    setEvents: function ($that) {
      var self = this;
      var $siteContainer = $that.nodes.$siteHeader.siblings('.site-container');
      var siteWideEnabled =
      $that.nodes.$siteHeader.hasClass('site-wide-banner--enabled') ||
      $that.nodes.$siteHeader.hasClass('site-wide-banner--disabled');

      $that.nodes.$searchIcon
        .off('click.searchEvents mouseenter.searchEvents mouseleave.searchEvents')
        .on(self.event + '.searchEvents', function (e) {
          e.preventDefault();
          e.stopPropagation();
          var $parent = $(this).closest(self.selector.headerSearch);
          var isToggleDisable = $parent.hasClass('js-disable-toggle');

          if (isToggleDisable && !self.isMobile) {
            return;
          }
          if ($('.js-site-navigation__item', $that.nodes.$siteHeader).hasClass('is-active')) {
            $('.js-site-navigation__item.is-active > a').trigger('click');
          }
          // Disable backdrop scrolling to avoid ghosting
          self.backDropScrolling(false, $that);

          var gnavItems = ['.js-site_email_signup', '.js-site_bag', '.js-site-my-mac'];

          gnavItems.forEach(function (item) {
            var closeElem = item + '__contents-close';

            if ($that.nodes.$siteHeader.find(item).hasClass('active')) {
              $that.nodes.$siteHeader.find(closeElem).trigger('click');
            }
          });

          if ($that.nodes.$searchForm.hasClass('header-search__form-remove')) {
            $that.nodes.$searchIcon.addClass('search-active');
            if (siteWideEnabled) {
              $that.nodes.$siteHeader
                .removeClass('site-wide-banner--enabled')
                .addClass('site-wide-banner--disabled');
            }
            $that.nodes.$html.addClass('search-active');
            $that.nodes.$searchOverlayLanding.length > 0 ? $that.nodes.$searchOverlayLanding.removeClass('hidden') : null;
            $that.nodes.$searchTrendingTitle.length > 0 ? $that.nodes.$searchTrendingTitle.removeClass('hidden') : null;
            $that.nodes.$searchOverlayBg.length > 0 ? $that.nodes.$searchOverlayBg.removeClass('hidden') : null;
            $that.nodes.$searchForm.removeClass('header-search__form-remove');
            $that.find('.js-search-field').trigger('focus');
            $that.on('mouseleave.searchEvents', function () {
              self.leaveHeader($that);
            });
            $('#search', $that).trigger('focus');
            $that.on('clickoutside.searchEvents', function () {
              $that.nodes.$searchIcon.trigger('click');
              $that.nodes.$searchIcon.removeClass('search-active');
              if (siteWideEnabled) {
                $that.nodes.$siteHeader
                  .removeClass('site-wide-banner--disabled')
                  .addClass('site-wide-banner--enabled');
              }
              $that.nodes.$html.removeClass('search-active');
              $that.nodes.$searchForm.addClass('header-search__form-remove');
              $that.nodes.$searchOverlayLanding.length > 0 ? $that.nodes.$searchOverlayLanding.addClass('hidden') : null;
              $that.nodes.$searchTrendingTitle.length > 0 ? $that.nodes.$searchTrendingTitle.addClass('hidden') : null;
              $that.nodes.$searchOverlayBg.length > 0 ? $that.nodes.$searchOverlayBg.addClass('hidden') : null;
              $that.nodes.$dimOverlay.remove();
            });
            $.sidr('close', 'mobile-navigation');
            $that.nodes.$dimOverlay.prependTo($siteContainer);
          } else if (self.isMobile) {
            $that.off('clickoutside.searchEvents');
            $that.nodes.$searchInput.val('');
            $that.nodes.$searchIcon.removeClass('search-active');
            $that.nodes.$html.removeClass('search-active');
            $that.nodes.$searchForm.addClass('header-search__form-remove');
            $that.nodes.$searchOverlayLanding.length > 0 ? $that.nodes.$searchOverlayLanding.addClass('hidden') : null;
            $that.nodes.$searchTrendingTitle.length > 0 ? $that.nodes.$searchTrendingTitle.addClass('hidden') : null;
            $that.nodes.$searchOverlayBg.length > 0 ? $that.nodes.$searchOverlayBg.addClass('hidden') : null;
            $that.nodes.$searchInput.blur();
            $that.nodes.$dimOverlay.remove();
          }
        })
        .on('mouseleave.searchEvents', function () {
          self.leaveIcon($that);
        });

      if ($that.nodes.$searchIcon.closest('.js-disable-toggle').length > 0 && !self.isMobile) {
        $that.nodes.$searchIcon.off('click').on('click', function (e) {
          e.preventDefault();
        });
        $that.nodes.$searchInput
          .off('click.searchEvents clickoutside.searchEvents')
          .on('click.searchEvents', function () {
            $that.nodes.$searchForm.toggleClass('header-search__form-remove', false);
            self.openSearchOverlay($that);
            $(document).trigger('check_reset_gnav_radio_buttom');
          })
          .on('clickoutside.searchEvents', function (e) {
            $that.nodes.$searchForm.toggleClass('header-search__form-remove', true);
            self.closeSearchOverlay($that);
          });
      } else if (self.isMobile) {
        $that.nodes.$searchInput.unbind('clickoutside.searchEvents');
      }
      // Just for closing icon in the form
      $that.nodes.$searchClose.off('click').on('click.searchEvents', function () {
        self.closeSearch($that);
      });
      $that.nodes.$searchInput.on('keydown.searchEvents', function (e) {
        self.typeSearchDown(e, $that);
      });
      $that.nodes.$searchInput.on('keyup.searchEvents', function () {
        self.typeSearchUp($that);
      });
      $that
        .find('.header-search__view-toggle')
        .on('click.searchEvents', self.toggleProductList($that));
    },

    backDropScrolling: function (enable, $that) {
      if (!enable) {
        $that.nodes.$body.css({
          width: '100vw',
          height: '100vh',
          overflow: 'hidden'
        });
        $that.nodes.$viewportMeta.attr('content', 'width=device-width,initial-scale=1,maximum-scale=1');
      } else {
        $that.nodes.$body.removeAttr('style');
        $that.nodes.$viewportMeta.attr('content', 'width=device-width,initial-scale=1');
      }
    },

    typeSearchUp: function ($that) {
      if (!$that.nodes.$searchInput.val()) {
        $that.find('.header-search__suggestions > a').text('');
      }
      setTimeout(function () {
        $that.nodes.$headerResults.find('.js-product-typeahead').length > 0 ? $that.nodes.$searchOverlayBg.removeClass('hidden') : $that.nodes.$searchOverlayBg.addClass('hidden');
      }, 1000);
      $that.nodes.$searchOverlayLanding.add($that.nodes.$searchTrendingTitle).addClass('hidden');
    },

    typeSearchDown: function (e, $that) {
      if (e.keyCode === 9) {
        // Tab key
        var suggestion = $that.find('.header-search__suggestions > a').text();

        $(this).attr('value', suggestion);
      }
    },

    openSearchOverlay: function ($that) {
      if (!$that.nodes.$searchInput.val()) {
        $that.nodes.$headerSearchResults.add($that.nodes.$searchTrendingTitle).removeClass('hidden');
        $that.nodes.$searchOverlayLanding.length > 0 ? $that.nodes.$searchOverlayBg.removeClass('hidden') : $that.nodes.$searchOverlayBg.addClass('hidden');
        $that.nodes.$searchOverlayLanding.removeClass('hidden');
      } else {
        $that.nodes.$searchOverlayBg.addClass('hidden');
        $that.nodes.$headerSearchResults.hasClass('no-prods') ? $that.nodes.$headerSearchResults.addClass('hidden') : null;
      }
    },

    closeSearchOverlay: function ($that) {
      $that.nodes.$headerSearchResults.add($that.nodes.$searchOverlayLanding).add($that.nodes.$searchTrendingTitle).add($that.nodes.$searchOverlayBg).addClass('hidden');
    },

    toggleProductList: function ($that) {
      $('.js-search-results__results .product', $that).toggleClass('product--list-view');
    },

    leaveHeader: function ($that) {
      var self = this;

      $that.nodes.$searchIcon.trigger('click');
      $that.nodes.$searchIcon.removeClass('search-active');
      $that.nodes.$html.removeClass('search-active');
      $that.blur();
      $that.nodes.$dimOverlay.remove();
      self.backDropScrolling(true, $that);
    },

    leaveIcon: function ($that) {
      var self = this;
      var $parent = $(this).closest(self.selector.headerSearch);
      var isToggleDisable = $parent.hasClass('js-disable-toggle');

      if (isToggleDisable && !self.isMobile) {
        return;
      }
      $that.unbind('clickoutside');
      if ($that.nodes.$searchForm.hasClass('header-search__form-remove')) {
        $that.nodes.$searchIcon.removeClass('search-active');
        $that.nodes.$html.removeClass('search-active');
        $that.nodes.$searchInput.val('');
        $that.nodes.$searchInput.blur();
        $that.nodes.$dimOverlay.remove();
      }
    },

    closeSearch: function ($that) {
      var self = this;
      var siteWideEnabled =
      $that.hasClass('site-wide-banner--enabled') ||
      $that.hasClass('site-wide-banner--disabled');

      $that.nodes.$searchForm.addClass('header-search__form-remove');
      $that.nodes.$searchOverlayLanding.length > 0 ? $that.nodes.$searchOverlayLanding.addClass('hidden') : null;
      $that.nodes.$searchInput.val('');
      $that.nodes.$searchIcon.removeClass('search-active');
      if (siteWideEnabled) {
        $that.nodes.$siteHeader
          .removeClass('site-wide-banner--disabled')
          .addClass('site-wide-banner--enabled');
      }
      $that.nodes.$html.removeClass('search-active');
      $that.nodes.$searchInput.blur();
      $that.nodes.$dimOverlay.remove();
      self.backDropScrolling(true, $that);
    },

    checkIfMobile: function () {
      var currentBpWidth = parseInt(Unison.fetch.now().width, 10);
      var bPlargeUp = parseInt(Unison.fetch.all()['usn-medium'], 10);

      return currentBpWidth <= bPlargeUp;
    }
  };
})(jQuery);
